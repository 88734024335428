document.addEventListener('DOMContentLoaded', () => {
    const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('.relative input');
    const toggleClass: string = '-translate-y-4';
    inputs.forEach((input:HTMLInputElement) => {
        const label = input.nextElementSibling as HTMLElement;

        input.addEventListener('focus', () => {
            label.classList.add(toggleClass);
        });

        input.addEventListener('blur', () => {
            console.log(!input.value.trim());
            if (!input.value.trim()) {
                console.log('here')
                if(!input.value) label.classList.remove(toggleClass);
            }
        });

        // Check initial state
        if (input.value.trim() !== '') {
            label.classList.add(toggleClass);
        }
    });
});