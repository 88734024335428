// import Splide from '@splidejs/splide';
// import '@splidejs/splide/css';
import { HomepageHeroSlider } from '../homepage/hero-slider';
import { OffersSlider } from '../offers-slider';

document.addEventListener("DOMContentLoaded", function () {
    const programForm: HTMLElement | null = document.querySelector(
        "#rappelContactFormDialog",
    );
    const closeRappelContactFormDialog: HTMLElement | null = document.querySelector(
        "#closeRappelContactFormDialog",
    );

    let sliderType: String | null

    document.querySelectorAll(".openRappelForm")?.forEach((element) => {
        element?.addEventListener("click", () => {
            programForm?.classList.toggle("hidden");
            document.querySelector('body')?.classList.add("overflow-hidden");

            sliderType = element.getAttribute("data-slider-type");
            const targetSection = programForm?.querySelector(`[data-slider-type="${sliderType}"]`);
            if (targetSection) {
                let slider
                console.log('sliderType', sliderType)
                if(sliderType === 'image') {
                    slider = new HomepageHeroSlider('.programContactOffersSlider')
                    if(slider) targetSection.classList.remove('hidden');
                } else {
                    slider = new OffersSlider('.programContactSlider')
                    targetSection.classList.remove('hidden');
                }
            } else {
                programForm?.querySelector('.fallbackImage')?.classList.remove('hidden');
            }
        });
    });

    closeRappelContactFormDialog?.addEventListener("click", () => {
        programForm?.classList.add("hidden");
        document.querySelector('body')?.classList.remove("overflow-hidden");
        if (sliderType) {
            programForm?.querySelector(`[data-slider-type="${sliderType}"]`)?.classList.add('hidden');
        } else {
            programForm?.querySelector('.fallbackImage')?.classList.add('hidden');
        }
    });

    programForm?.addEventListener("submit", (event) => {
        event.preventDefault();

        const formData = new FormData(event.target as HTMLFormElement);
        console.log("submitting form", Array.from(formData.entries()));
    });
});
