
import axios from 'axios'

document.addEventListener('DOMContentLoaded', () => {
    const formModal: HTMLElement | null = document.querySelector('.vendreTerrainFormModal')
    const form: HTMLElement | null = document.querySelector('#vendreTerrainForm')
    const submitBtn: HTMLElement | null = document.querySelector('.submitTerrainFormBtn')
    const openVendreTerrainFormBtn: HTMLElement | null = document.querySelector('.openFormModalBtn')
    const closeVendreTerrainFormBtn: HTMLElement | null = document.querySelector('#closeTerrainForm')

    if(!formModal) return;

    openVendreTerrainFormBtn?.addEventListener('click', () => {
        formModal.classList.toggle('hidden');
    })

    closeVendreTerrainFormBtn?.addEventListener('click', () => {
        formModal.classList.add('hidden');
    })

    const displayMessage = (type: 'success' | 'error', message: string) => {
        const messageContainer: HTMLElement | null = formModal.querySelector('.formMessage');

        if(!messageContainer) return;

        messageContainer.classList.remove('hidden');
        messageContainer.classList.add(type === 'success' ? 'bg-green' : 'bg-red');
        messageContainer.innerText = message;

        setTimeout(() => {
            messageContainer.classList.add('hidden');
        }, 5000);
    }

    const toggleButtonSubmit = (isDisabled: boolean) => {
        submitBtn?.setAttribute('disabled', isDisabled ? 'disabled' : '');
        
        if (isDisabled) {
            submitBtn?.classList.add('cursor-not-allowed', 'opacity-50');
            submitBtn?.classList.remove('cursor-pointer', 'opacity-100');
        } else {
            submitBtn?.classList.remove('cursor-not-allowed', 'opacity-50');
            submitBtn?.classList.add('cursor-pointer', 'opacity-100');
        }
    };

    const submitTerrainForm = async (formData: FormData) => {
        try {
            toggleButtonSubmit(true);

            // @ts-ignore
            const response = await axios.post(urls.ajax, formData);

            if(response.status !== 200) throw new Error('Failed to submit form');

            console.log(response);
            displayMessage('success', 'Votre message a bien été envoyé');

        } catch (error) {
            console.error('Error submitting terrain form', error);
            displayMessage('error', 'Une erreur est survenue lors de la soumission du formulaire.');
        } finally {
            toggleButtonSubmit(false);
        }
    }

    form?.addEventListener('submit', (e) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        if(!formData) return;
        submitTerrainForm(formData);
    })
})