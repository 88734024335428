<script setup>
import { ref, computed, onMounted, watch, nextTick, watchEffect } from "vue";
import axios from "axios";
// import { set } from "lodash";
import { useScriptTag } from '@vueuse/core'
import { set } from "lodash";

// useScriptTag(
//   'https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js',
//   // on script tag loaded.
//   (el) => {
//     console.log('script loaded', el)
//   },
// )

const props = defineProps({
    programs: {
        type: Array,
        default: [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
});

const retries = ref(0);

const emit = defineEmits([
    "update:programs",
    "update:loading",
]);

const loadingMap = ref(true);
const markers = ref([]);
const mapBoxRef = ref('map_box')
const cluster = ref(null);
const map = ref({});
const mapElement = ref(null);
const mapInfoWindow = ref({});
const mapIsReady = ref(false);
const kimono = window.kimono;

onMounted(() => {
    console.log('mounted')
    initMap()
    stopLoading()
});

const computedPrograms = computed({
    get() {
        return props.programs;
    },
    set(programs) {
        emit("update:programs", programs);
    },
});

const computedLoading = computed({
    get() {
        return props.loading;
    },
    set(value) {
        emit("update:loading", value);
    },
});

const stopLoading = () => {
    loadingMap.value = false;
}

// Center map on markers
const fitMapToMarkers = (markerCluster) => {
    console.log('markerCluster', markerCluster)
    markerCluster.fitMapToMarkers();
}

const setMarkers = async (map) => {
    const programs = computed(()=> props.programs)
    var locations = props.programs?.map((program) => {
        return {
            lat: parseFloat(program.latitude),
            lng: parseFloat(program.longitude),
            title: program.title,
        };
    });

    console.log('locations are', locations)

    if(!locations) {
        console.error('No locations found ');
        return;
    }

    markers.value = locations.map(function (location, i) {
        let marker = new f4.map.Marker({
            position: {
                lat: location.lat,
                lng: location.lng,
            },
            map: map,
            title: location.title || i + 1,
            zIndex: 80,
            icon: {
                url:
                    urls.theme +
                    "/assets/images/placeholder.png",
            },
        });

        marker.addListener('mouseover', async () => {
            const program = computedPrograms.value[i];

            if(!program) {
                console.error('No program found !');
                return;
            }
            
            try {
                let data = new FormData();
                data.append("action", "search_infowindow");
                data.append("id", program.id);

                const response = await axios.post(urls.ajax, data);

                if(response.status !== 200) throw new Error('Error getting program data for map')

                mapInfoWindow.value.setContent(
                    response.data
                );

                mapInfoWindow.value.open(
                    map,
                    marker
                );

            } catch (error) {
                console.error(error);   
            }
        });

        marker.addListener('click', () => {
            console.log('clicked ')
        });

        if(marker) return marker;
    });

    if(!markers.value || !map) {
        console.error('No markers found ');
        return;
    }
    
    cluster.value = new MarkerClusterer(
        map,
        markers.value,
        {
            imagePath: urls.theme + "/assets/images/cluster.png",
            styles: [
                {
                    url: urls.theme + "/assets/images/cluster.png",
                    height: 24,
                    width: 24,
                    textColor: "#FFFFFF",
                },
                {
                    url: urls.theme + "/assets/images/cluster.png",
                    height: 24,
                    width: 24,
                    textColor: "#FFFFFF",
                },
                {
                    url: urls.theme + "/assets/images/cluster.png",
                    height: 24,
                    width: 24,
                    textColor: "#FFFFFF",
                },
                {
                    url: urls.theme + "/assets/images/cluster.png",
                    height: 24,
                    width: 24,
                    textColor: "#FFFFFF",
                },
                {
                    url: urls.theme + "/assets/images/cluster.png",
                    height: 24,
                    width: 24,
                    textColor: "#FFFFFF",
                },
            ],
            minimumClusterSize: 5,
        }
    );

    stopLoading()

    // map.setCenter(new f4.map.LatLng(locations[0].latitude, locations[0].longitude));
    fitMapToMarkers(cluster.value)
}

const initMap = async () => {
    try {
        const mapContainer = mapBoxRef.value;
        // console.log('markers', markers.value)
        // console.log('cluster', cluster.value)

        if (!mapContainer) {
            console.error('Map container not found');
            return;
        }

        if (cluster.value !== null) {
            cluster.value.clearMarkers();
        }
        const domEl = document.getElementById('map_box');
        const map = kimono.addMap({
            zoom: 6,
            theta: 55,
            phi: 5,
            lon: 2.333333,
            lat: 48.866667,
            container: domEl,
        });


        // markers.value.forEach((marker) => {
        //     console.log('markers.value.forEach ', marker)
        //     marker?.initMap(null)
        // });
        // markers.value = [];


        map.on("error", function (err) {
            console.error('Error mapElement', err);
        });
 
        map.on("load", function (realEstateAd, mapElement) {
             mapInfoWindow.value = new f4.map.InfoWindow({
            content: "",
            closeButton: false,
            zIndex: 100,
            });
            setMarkers(mapElement); 
        });

    } catch (error) {
        console.error('Error mounting map :', error);
        retries.value++;
        if(retries.value < 3) {
            console.log('retries count', retries.value)
            initMap();
        }
    }
}

</script>

<template>
    <div v-if="loadingMap" class="absolute inset-0 mx-auto animate-pulse">Chargement...</div>
    <div
        class="w-full h-full" id="map_box" ref="mapBoxRef"
    ></div>
</template>

<!-- REMOVE THIS WHEN REFACTO OK -->

<!-- // const setMarkers = (map) => {
//     if(map !== null) {
//         if (map === null) {
//             setTimeout(function () {
//                 setMarkers();
//             }, 1000);
//         } else {
//             if (cluster.value !== null) {
//                 cluster.value.clearMarkers();
//             }

//             markers.value.forEach((marker) => {
//                 marker.initMap(null)
//             });

//             markers.value = [];

//             computedPrograms.value?.forEach((program) => {
//                 console.log('lat', parseFloat(program.latitude), 'lon', parseFloat(program.longitude))
//                 if (
//                     program.latitude !== 0 &&
//                     program.longitude !== 0
//                 ) {
//                     let marker = new f4.map.Marker({
//                         position: {
//                             lat: parseFloat(program.latitude),
//                             lng: parseFloat(program.longitude)
//                         },
//                         map: map,
//                         clickable: true,
//                         zIndex: 80,
//                         icon: {
//                             url:
//                                 urls.theme +
//                                 "/assets/images/placeholder.png",
//                         },
//                     });

//                     marker.addListener("mouseover", async () => {
//                             // gtm.gae(
//                             //     "Carte des programmes",
//                             //     "Survole",
//                             //     program.id
//                             // );
//                         let data = new FormData();
//                         data.append("action", "search_infowindow");
//                         data.append("id", program.id);
//                         await axios
//                             .post(urls.ajax, data)
//                             .then((response) => {
//                                 mapInfoWindow.value.setContent(
//                                     response.data
//                                 );
//                                 mapInfoWindow.value.open(
//                                     map,
//                                     marker
//                                 );
//                             })
//                             .catch((error) => console.error(error));
//                     });

//                     marker.addListener("click", () => {
//                         // gtm.gae(
//                         //     "Carte des programmes",
//                         //     "Clique",
//                         //     program.id
//                         // );
//                         window.location.href = program.permalink;
//                     });

//                     marker.addListener("mouseout", () => {
//                         mapInfoWindow.value.close();
//                     });

//                     if(marker) markers.value.push(marker);
//                 }
//             });

//             cluster.value = new MarkerClusterer(
//                 map,
//                 markers.value,
//                 {
//                     imagePath: urls.theme + "/assets/images/cluster.png",
//                     styles: [
//                         {
//                             url: urls.theme + "/assets/images/cluster.png",
//                             height: 24,
//                             width: 24,
//                             textColor: "#FFFFFF",
//                         },
//                         {
//                             url: urls.theme + "/assets/images/cluster.png",
//                             height: 24,
//                             width: 24,
//                             textColor: "#FFFFFF",
//                         },
//                         {
//                             url: urls.theme + "/assets/images/cluster.png",
//                             height: 24,
//                             width: 24,
//                             textColor: "#FFFFFF",
//                         },
//                         {
//                             url: urls.theme + "/assets/images/cluster.png",
//                             height: 24,
//                             width: 24,
//                             textColor: "#FFFFFF",
//                         },
//                         {
//                             url: urls.theme + "/assets/images/cluster.png",
//                             height: 24,
//                             width: 24,
//                             textColor: "#FFFFFF",
//                         },
//                     ],
//                     minimumClusterSize: 5,
//                 }
//             );
//             cluster.value.fitMapToMarkers();
//         }
//     };

//     // mapIsReady.value = true;
// } -->