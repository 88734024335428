document.addEventListener("DOMContentLoaded", function () {
    const faqBtns: NodeListOf<HTMLElement> = document.querySelectorAll(
        ".displayFaqQuestionBtn",
    );
    faqBtns.forEach((btn: HTMLElement) => {
        btn.addEventListener("click", function () {
            btn.querySelector("svg")?.classList.toggle("rotate-180");
            btn.querySelector("svg")?.classList.toggle("stroke-white");
            btn.nextElementSibling?.classList.toggle("hidden");
            btn.classList.toggle("bg-primary");
            btn.classList.toggle("text-white");
        });
    });
});
