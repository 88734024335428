document.addEventListener("DOMContentLoaded", function () {
    const shorcutContainer = document.querySelector(".shortcut_container") as HTMLElement;
    const navContactBtn = document.querySelector(".openContactForm") as HTMLElement;
    console.log('set', shorcutContainer);

    const hideNavContactBtn = (hide = false) => {
        if(!navContactBtn) return;
        if(hide) {
            navContactBtn.classList.add("hidden");
        } else {
            navContactBtn.classList.remove("hidden");
        }
    }

    if (shorcutContainer) {
        window.addEventListener("scroll", function () {
            const scrollTop: number = window.scrollY;
            if (scrollTop >= 300) {
                shorcutContainer.classList.remove("shortcut_container-inactive");
                shorcutContainer.classList.add("shortcut_container-active");
                hideNavContactBtn(true);
            }
            else {
                hideNavContactBtn();
                shorcutContainer.classList.remove("shortcut_container-active");
                shorcutContainer.classList.add("shortcut_container-inactive");
            }
        });
    }
});
