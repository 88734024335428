import axios from "axios"

document.addEventListener('DOMContentLoaded', () => {
    const immodvisorsContainer = document.querySelector('.immodvisors-container');
    if(immodvisorsContainer) {
        console.log('block is here');
        getImmodvisorsAvis()
    }
})

const getImmodvisorsAvis = async () => {
    try {
        //@ts-ignore
        const response = await axios.get(`${urls.ajax}?action=fetch_reviews_from_api`)
        console.log('respnse from immodvisors', response);
    } catch (error) {
        console.error(error);
        
    }
}
