<script setup lang="ts">
import { reactive, ref } from 'vue';
import SimpleInput from '@/vue/inputs/SimpleInput.vue';
import axios from 'axios';

const submitButton = ref<HTMLButtonElement | null>(null);

const formData = reactive({
    lastname: '',
    firstname: '',
    phone_number: '',
    email: '',
    address: '',
    city: '',
    zip_code: '',
    date: '',
});

const filleuls = reactive([
    {
        lastname: '',
        firstname: '',
        phone_number: '',
        email: '',
        address: '',
        city: '',
        zip_code: '',
        date: '',
    },
]);

const addFilleul = () => {
    if (filleuls.length >= 3) return;
    filleuls.push({
        lastname: '',
        firstname: '',
        phone_number: '',
        email: '',
        address: '',
        city: '',
        zip_code: '',
        date: '',
    });
};

const removeFilleul = () => {
    filleuls.pop();
};

const submitForm = async () => {
    try {
        const data: object = {
            ...formData,
            filleuls,
        };
        toggleButtonSubmit(true);
        // @ts-ignore
        console.log('submitting form', data, 'to url', window.urls.ajax);
        // @ts-ignore
        const response = await axios.post(window.urls.ajax, data);

        if (response.status !== 200) throw new Error('Failed to submit form');

        console.log(response);
    } catch (error) {
        console.error(error);
    } finally {
        toggleButtonSubmit(false);
    }
}

const toggleButtonSubmit = (isDisabled: boolean) => {
    submitButton.value?.setAttribute('disabled', isDisabled ? 'disabled' : '');
    submitButton.value?.classList.add(isDisabled ? 'cursor-not-allowed' : 'cursor-pointer');
    submitButton.value?.classList.add(isDisabled ? 'opacity-50' : 'opacity-100');
}
</script>

<template>
    <form @submit.prevent="submitForm" class="py-14 md:px-20">
        <div class="p-8 md:p-16 space-y-6 md:space-y-8 bg-light rounded">
            <h2 class="text-primary text-4xl font-regular uppercase text-center">
                Vos coordonnées
            </h2>

            <div class="grid md:grid-cols-2 gap-6 md:gap-8">
                <SimpleInput placeholder="Nom*" name="lastname" v-model="formData.lastname" required />
                <SimpleInput placeholder="Prénom*" name="firstname" v-model="formData.firstname" required />
                <SimpleInput placeholder="Téléphone*" name="phone_number" type="tel" v-model="formData.phone_number"
                    required />
                <SimpleInput placeholder="Email*" name="email" type="email" v-model="formData.email" required />
                <SimpleInput placeholder="Adresse*" name="address" v-model="formData.address" required />
                <SimpleInput placeholder="Ville*" name="city" v-model="formData.city" required />
                <SimpleInput placeholder="Code postal*" name="zip_code" v-model="formData.zip_code" required />
                <SimpleInput placeholder="Date de réservation" name="date" type="date" v-model="formData.date"
                    required />
            </div>
        </div>
        <div class="p-8 md:p-16 space-y-6 md:space-y-8 bg-primary rounded">
            <h2 class="text-white text-4xl font-regular uppercase text-center">
                Coordonnées de votre filleul
            </h2>

            <div v-for="(filleul, index) in filleuls" :key="index" class="grid md:grid-cols-2 gap-6 md:gap-8">
                <SimpleInput theme="dark" v-model="filleul.lastname" placeholder="Nom*" :name="`lastname-${index}`" />
                <SimpleInput theme="dark" v-model="filleul.firstname" placeholder="Prénom*"
                    :name="`firstname-${index}`" />
                <SimpleInput theme="dark" type="tel" v-model="filleul.phone_number" placeholder="Téléphone*"
                    :name="`phone_number-${index}`" />
                <SimpleInput theme="dark" type="email" v-model="filleul.email" placeholder="Email*"
                    :name="`email-${index}`" />
                <SimpleInput theme="dark" v-model="filleul.address" placeholder="Adresse*" :name="`address-${index}`" />
                <SimpleInput theme="dark" v-model="filleul.city" placeholder="Ville*" :name="`city-${index}`" />
                <SimpleInput theme="dark" v-model="filleul.zip_code" placeholder="Code postal*"
                    :name="`zip_code-${index}`" />
                <SimpleInput theme="dark" type="date" v-model="filleul.date" placeholder="Date de réservation"
                    :name="`date-${index}`" />

                <div class="col-span-full flex items-center justify-center gap-6">
                    <button v-if="filleuls.length > 1" type="button"
                        class="inline-flex items-center gap-1 p-1 pl-2 text-secondary uppercase bg-white rounded"
                        @click="removeFilleul">
                        Retirer un filleul
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-4 -rotate-90">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                        </svg>
                    </button>
                    <button v-if="filleuls.length - 1 == index && filleuls.length < 3" type="button"
                        class="inline-flex items-center gap-1 p-1 pl-2 text-secondary uppercase bg-white rounded"
                        @click="addFilleul">
                        Ajouter un filleul
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div class="p-10 flex flex-col items-center gap-6">
            <p class="text-primary text-center">
                *Champs obligatoire
            </p>
            <label class="text-center">
                <input type="checkbox">
                J'accepte que mes données soient utilisées par Les Nouveaux Constructeurs et CFH
                <br>à des fins de prospection commerciale et pour recevoir des offres et promotions
            </label>
            <button type="submit" ref="submitButton"
                class="p-4 pr-3 rounded text-white bg-primary uppercase font-bold inline-flex items-center gap-1">
                Envoyer
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>
            </button>
            <p class="text-xs text-justify">
                Vos données à caractère personnel et celles de vos filleuls seront utilisées par Les Nouveaux
                Constructeurs et CFH dans le cadre des opérations de parrainage, c’est-à-dire pour contacter vos
                filleuls au moyen des coordonnées que vous avez renseigné et pour vous accorder l’allocation prévue en
                cas de réservation confirmée par l’un de vos filleuls. En fonction de vos choix, elles peuvent aussi
                être utilisées pour vous adresser des messages de prospection commerciale. Vous disposez d'un droit
                d'accès, de rectification, de portabilité, d’effacement de vos données à caractère personnel, d’un droit
                à la limitation du traitement, ainsi que d’un droit d’opposition au traitement de vos données. Vous
                disposez également du droit de nous faire parvenir des directives spéciales relatives au sort de vos
                données à caractère personnel en cas de décès, et d’introduire une réclamation auprès de l’autorité de
                contrôle compétente. Pour en savoir plus sur la gestion de vos données personnelles, vos droits et
                comment exercer vos droits, vous pouvez consulter notre Politique de protection des Données Personnelles
                en cliquant sur le lien suivant
                : https://www.lesnouveauxconstructeurs.fr/politiques-de-protection-des-donnees-et-politique-de-cookies/
            </p>
        </div>
    </form>
</template>
